import {
  chain,
  curry,
  filter,
  find,
  get,
  includes,
  size,
  trimStart,
  values,
} from 'lodash';
import { createSelector } from 'reselect';

import { ENTITY_TYPE_POSTS } from '@wix/communities-blog-client-common';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import {
  sortCategoryPosts,
  sortDrafts,
  sortFeedPosts,
  sortProfilePosts,
} from '@wix/communities-blog-universal/dist/src/utils/sort-posts';

import { getLocalizedYearAndMonth } from '../helpers/date';
import { secondParam } from '../services/param-selectors';

import { isValidAdvancedSlug } from '../services/slug';
import { getTimezone } from '../store/basic-params/basic-params-selectors';
import {
  getCurrentPageEntities,
  getEntitiesByPage,
} from './pagination-selectors';

export const getPostMap = (state) => state.posts;

export const getPostCount = createSelector(getPostMap, (posts) => size(posts));

export const getPost = createSelector(
  [getPostMap, secondParam],
  (posts, postId) => posts[postId],
);

export const getPostBySlug = createSelector(
  [getPostMap, secondParam],
  (posts, slug) => {
    if (typeof slug !== 'string') {
      return undefined;
    }

    const lowerCaseSlug = slug.toLowerCase();
    const [year, month, postSlug] = trimStart(lowerCaseSlug, '/').split('/');
    if (isValidAdvancedSlug({ year, month, postSlug })) {
      return find(
        posts,
        (post) =>
          includes(post.slugs, lowerCaseSlug) ||
          includes(post.slugs, `${lowerCaseSlug}/`),
      );
    }

    return find(posts, (post) => includes(post.slugs, lowerCaseSlug));
  },
);

export const getPostByIdOrSlug = (state, idOrSlug) =>
  getPost(state, idOrSlug) || getPostBySlug(state, idOrSlug);

export const getPostSlugById = (state, id) => getPost(state, id)?.slug;

const getEntityIdsByPage = (state, page) =>
  getEntitiesByPage(state, ENTITY_TYPE_POSTS)?.[page] ?? [];

const getEntityIdsByIdAndPage = (state, id, page) =>
  getEntityIdsByPage(state, page);

const getSiteMemberId = (state, siteMemberId) => siteMemberId;

const isPublished = (post) => post && post.status === POST_STATUS.published;

export const isScheduled = (post) =>
  post && post.status === POST_STATUS.scheduled;

export const getPostScheduleDate = (post) => post && post.scheduledPublishDate;

export const isUnpublished = (post) =>
  post && post.status === POST_STATUS.unpublished;

export const isInModeration = (post) =>
  post &&
  [POST_STATUS.in_review, POST_STATUS.in_moderation].indexOf(post.status) > -1;

const hasLegacyCategoryId = (categoryId, post) =>
  post && includes(post.categoryIds, categoryId);

const hasTagId = curry((tagId, post) => post && includes(post.tagIds, tagId));

const createdInYearAndMonth = (year, month, timeZone) => (post) => {
  const firstPublishedDate = getLocalizedYearAndMonth(
    post.firstPublishedDate,
    timeZone,
  );
  return (
    firstPublishedDate.year === parseInt(year, 10) &&
    firstPublishedDate.month === parseInt(month, 10)
  );
};

export const getSortedPostsByCategoryId = createSelector(
  [getPostMap, secondParam],
  (posts, legacyCategoryId) =>
    sortCategoryPosts(
      chain(posts)
        .filter((post) => hasLegacyCategoryId(legacyCategoryId, post))
        .filter(isPublished)
        .value(),
    ),
);

export const getPostsByCategoryIdAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage],
  (posts, legacyCategoryId, entityIds) => {
    return chain(entityIds)
      .map((id) => posts[id])
      .filter((post) => hasLegacyCategoryId(legacyCategoryId, post))
      .value();
  },
);

export const getSortedCategoryPosts = createSelector([getPostMap], (posts) =>
  sortCategoryPosts(posts),
);

export const getPostsByPage = createSelector(
  [getPostMap, getEntityIdsByPage],
  (posts, entityIds) => entityIds.map((id) => posts[id]),
);

export const getSortedPostsByArchiveDate = createSelector(
  [getPostMap, secondParam, getTimezone],
  (posts, { year, month }, timeZone) =>
    sortCategoryPosts(
      chain(posts).filter(createdInYearAndMonth(year, month, timeZone)).value(),
    ),
);

export const getPostsByArchiveDateAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage, getTimezone],
  (posts, { year, month }, entityIds, timeZone) =>
    chain(entityIds)
      .map((id) => posts[id])
      .filter(createdInYearAndMonth(year, month, timeZone))
      .value(),
);

export const getSortedPostsByTagId = createSelector(
  [getPostMap, secondParam],
  (posts, tagId) =>
    sortCategoryPosts(
      chain(posts).filter(hasTagId(tagId)).filter(isPublished).value(),
    ),
);

export const getPostsByTagIdAndPage = createSelector(
  [getPostMap, secondParam, getEntityIdsByIdAndPage],
  (posts, tagId, entityIds) =>
    chain(entityIds)
      .map((id) => posts[id])
      .filter(hasTagId(tagId))
      .value(),
);

export const getFeedPosts = createSelector([getPostMap], (postsById) =>
  sortFeedPosts(values(postsById)),
);

export const getMyPosts = createSelector([getPostMap], (posts) =>
  sortProfilePosts(posts),
);

export const getPostListPosts = createSelector([getPostMap], (posts) =>
  values(posts),
);

export const getFeedPostsByPage = createSelector(
  [getPostMap, getEntityIdsByPage],
  (posts, entityIds) => entityIds.map((id) => posts[id]),
);

const hasSiteMemberId = curry(
  (siteMemberId, post) => post?.owner?.siteMemberId === siteMemberId,
);

export const getSortedPostsBySiteMemberId = createSelector(
  [getPostMap, getSiteMemberId],
  (posts, siteMemberId) =>
    sortProfilePosts(
      chain(posts)
        .filter(hasSiteMemberId(siteMemberId))
        .filter(isPublished)
        .value(),
    ),
);

export const getSortedDraftsBySiteMemberId = createSelector(
  [getPostMap, getSiteMemberId],
  (posts, siteMemberId) =>
    sortDrafts(
      chain(posts)
        .filter(hasSiteMemberId(siteMemberId))
        .filter(isUnpublished)
        .value(),
    ),
);

export const getPublishedPosts = createSelector([getPostMap], (posts) =>
  filter(posts, isPublished),
);
export const getDrafts = createSelector([getPostMap], (posts) =>
  filter(posts, isUnpublished),
);

export const getPublishedPostCount = createSelector([getPostMap], (posts) =>
  chain(posts).filter(isPublished).size().value(),
);

export const getDraftCount = createSelector([getPostMap], (posts) =>
  chain(posts).filter(isUnpublished).size().value(),
);

export const getPostsForPage = createSelector(
  [getPostMap, getCurrentPageEntities],
  (posts, entityIds) =>
    chain(entityIds)
      .map((id) => posts?.[id])
      .compact()
      .value(),
);

export const getLastPublishedDate = (post) => post.lastPublishedDate;

export const getPostCoverImage = (post) => post.coverImage;

export const getPostCoverImageSrc = (post) => getPostCoverImage(post)?.src;
